import { persistentMap } from "@nanostores/persistent";

type Settings = {
  onGoingOAuth2Process?: string;
  prefetchTootsDisabled?: boolean;
  language?: string;
  region?: string;

  // GIFs and Videos
  autoPlayGIFs?: boolean;
  autoPlayVideos?: boolean;
};

export const $settings = persistentMap<Settings>(
  "settings::",
  {},
  {
    encode: JSON.stringify,
    decode: JSON.parse,
  },
);
