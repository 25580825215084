import { createContext, useContext, type Accessor } from "solid-js";
import { useRegisterSW } from "virtual:pwa-register/solid";

export function isiOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

export type ServiceWorkerService = {
  needRefresh: Accessor<boolean>;
  offlineReady: Accessor<boolean>;
  serviceWorker: Accessor<ServiceWorker | undefined>;
};

const ServiceWorkerContext = /* @__PURE__ */ createContext<
  ServiceWorkerService
>(({
  needRefresh: () => false,
  offlineReady: () => false,
  serviceWorker: () => undefined
}));

export const ServiceWorkerProvider = ServiceWorkerContext.Provider;

export function useServiceWorker(): ServiceWorkerService {
  return useContext(ServiceWorkerContext);
}
