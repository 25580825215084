//! This module has side effect.
//! It recommended to include the module by <script> tag.
if (typeof document.body.animate === "undefined") {
  // @ts-ignore: this file is polyfill, no exposed decls
  import("web-animations-js").then(() => {
    // all target platforms supported, prepared to remove
    console.warn("web animation polyfill is included");
  });
}

if (typeof window.crypto.randomUUID === "undefined") {
  // Chrome/Edge 92+
  // https://stackoverflow.com/a/2117523/2800218
  // LICENSE: https://creativecommons.org/licenses/by-sa/4.0/legalcode
  window.crypto.randomUUID =
    function randomUUID(): `${string}-${string}-${string}-${string}-${string}` {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
        (
          +c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
        ).toString(16),
      ) as `${string}-${string}-${string}-${string}-${string}`;
    };
}
